// スライダーの処理
// sliderClassはスライダーのクラス名を指定
// sliderLengthはスライダーの数（切り替わる数）を指定
// sliderTimeはスライダーの切り替わる時間(ms)を指定(msなので5秒指定したいときは5000)
// 例: Slider(".slider", 3, 5000);
// data-slider属性にスライダーの数が表示されるのでCSSで表示を変更する
// 例: .slider[data-slider="1"] { opacity: 1; }
/*　HTML記述例
<ul class="slider js-slider" data-slider="0">
	<li class="slider-item">スライダー１</li>
    <li class="slider-item">スライダー2</li>
    <li class="slider-item">スライダー3</li>
</ul> */

export default class Slider {
	constructor(sliderClass, sliderLength, sliderTime) {
		this.count = 1;
		this.slider = document.querySelectorAll(sliderClass);
		this.sliderLength = sliderLength;
		this.sliderTime = sliderTime;
		this.intervalId = null;
	}

	updateSlider() {
		this.slider.forEach((ele) => {
			ele.setAttribute('data-slider', this.count.toString());
		});
	}

	counter() {
		this.count += 1;
		if (this.count > this.sliderLength) {
			this.count = 1;
		}
		this.updateSlider();
		this.intervalId = setTimeout(() => this.counter(), this.sliderTime);
	}

	start() {
		const isAlreadyActive = Array.from(this.slider).some((ele) =>
			ele.classList.contains('is-active')
		);

		if (isAlreadyActive) {
			return;
		}
		this.count = 1;
		this.updateSlider();
		this.intervalId = setTimeout(() => this.counter(), this.sliderTime);
		this.slider.forEach((ele) => {
			ele.classList.add('is-active');
		});
	}

	stop() {
		clearTimeout(this.intervalId);
		this.slider.forEach((ele) => {
			ele.setAttribute('data-slider', '0');
			ele.classList?.remove('is-active');
		});
	}
}
