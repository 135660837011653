// css
import '../../scss/pages/page-leimondchildren.scss';

// utils
import foundation from '../_utils/foundation';
foundation();

// npm
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// libs
import LinePathRepeat from '../_libs/LinePathRepeat';
import LinePath from '../_libs/LinePath';
import ScrollObserver from '../_libs/ScrollObserver';
import SplitText from '../_libs/SplitText';
import SplitSVGText from '../_libs/SplitSVGText';
import Slider from '../_libs/Slider';
import ScrollThrottle from '../_libs/ScrollThrottle';

document.addEventListener('DOMContentLoaded', () => {
	new SplitText('.js-title-target', {
		target: '.js-title-text',
		animation: [90],
	});
	new SplitSVGText('.js-title-svg-target', {
		target: '.js-title-svg-text',
		animation: [70],
	});

	// fvアニメーション
	setTimeout(() => {
		fv();
	}, 500);

	const scrollObserver_block = new ScrollObserver({
		target: '.js-block-target',
		addClass: 'is-active',
		once: true,
		option: {
			rootMargin: '0px 0px -25% 0px',
		},
	});
	// 監視開始;
	scrollObserver_block.init();

	const items = document.querySelectorAll('.js-bounce-item');

	// 各要素にアニメーションを適用
	items.forEach((item) => {
		// 初期状態を設定
		gsap.set(item, {
			opacity: 0,
			scale: 0.8,
		});

		// アニメーションを設定
		gsap.to(item, {
			opacity: 1,
			scale: 1,
			duration: 0.8,
			ease: 'elastic.out(1, 0.3)',
			scrollTrigger: {
				trigger: item,
				start: 'top 75%',
				toggleActions: 'play none none none',
			},
			onComplete: () => {
				// アニメーション完了0.5秒後に実行
				setTimeout(() => {
					// 要素内のjs-repeat-itemクラスを持つ子要素を探す
					const repeatItem = item.querySelector('.js-repeat-item');
					// 子要素が存在すれば、is-activeクラスを追加
					if (repeatItem) {
						repeatItem.classList.add('is-active');
					}
				}, 500); // 500ミリ秒 = 0.5秒
			},
		});
	});

	// Splide
	const slideContents = document.querySelectorAll('.splide__slide');
	const slideLength = slideContents.length;
	let splide = new Splide('#splide-nursing', {
		type: slideLength > 2 ? 'loop' : '',
		gap: 24,
		pagination: true,
		arrows: slideLength > 2 ? true : false,
		direction: 'ltr',
		breakpoints: {
			768: {
				perPage: 1.5,
				perMove: 1,
				height: '128px',
			},
			769: {
				perPage: 3,
				perMove: 3,
				height: 'auto',
			},
		},
	});

	splide.on('mounted move', function () {
		let slideCount = splide.length;
		let currentIndex = splide.index + 1;
		let counterElement = splide.root.querySelector('.splide__counter');
		if (counterElement) {
			counterElement.textContent = currentIndex + ' / ' + slideCount;
		}
	});

	splide.mount();

	const scrollObserver_target = new ScrollThrottle({
		target: '.js-facility-area',
		callback: (entry) => {
			if (entry.isIntersecting) {
				mySlider1.start();
				mySlider2.start();
				mySlider3.start();
			} else {
				mySlider1.stop();
				mySlider2.stop();
				mySlider3.stop();
			}
		},
		option: {
			rootMargin: '-40% 0px 0px 0px',
		},
		first: 0,
	});
	// 監視開始;
	scrollObserver_target.init();

	const mySlider1 = new Slider('.js-facility-slider1', 2, 3000);
	const mySlider2 = new Slider('.js-facility-slider2', 2, 3000);
	const mySlider3 = new Slider('.js-facility-slider3', 2, 3000);

	const slider1 = new LeimondSlider('.js-fvslider-item');
	slider1.startSlider();
});

window.addEventListener('load', () => {
	const line = new LinePath();
	line.observe();
});

function fv() {
	// js-fv-item1とjs-fv-item2にis-activeクラスを付与
	const itemsToActivate = [
		document.querySelector('.js-fv-item1'),
		document.querySelector('.js-fv-item2'),
		document.querySelector('.js-fv-item3'),
		document.querySelector('.js-fv-item4-1'),
		document.querySelector('.js-fv-item4-2'),
	];

	itemsToActivate.forEach((item) => {
		if (item) {
			item.classList.add('is-active');
		}
	});

	// アニメーション対象の要素を取得
	const itemsToAnimate = [];
	for (let i = 5; i <= 16; i++) {
		const elements = document.querySelectorAll(`.js-fv-item${i}`);
		elements.forEach((element) => itemsToAnimate.push(element));
	}
	// 各要素に対してアニメーションを設定
	itemsToAnimate.forEach((item, index) => {
		if (!item) return; // 要素が存在しない場合はスキップ

		// 初期状態を設定
		gsap.set(item, {
			opacity: 0,
			scale: 0.8,
		});

		// アニメーションを設定
		gsap.to(item, {
			opacity: 1,
			scale: 1,
			duration: 0.8,
			ease: 'elastic.out(1, 0.3)',
			delay: index * 0.05, // 各要素に遅延を追加

			onComplete: () => {
				// アニメーション完了0.5秒後に実行
				setTimeout(() => {
					// 要素内のjs-repeat-itemクラスを持つ子要素を探す
					const repeatItem = item.querySelector('.js-repeat-item');
					// 子要素が存在すれば、is-activeクラスを追加
					if (repeatItem) {
						repeatItem.classList.add('is-active');
					}
				}, 500);
			},
		});
	});

	setTimeout(() => {
		LinePathRepeat();
	}, 100);
}

class LeimondSlider {
	constructor(sliderClass) {
		this.slides = document.querySelectorAll(sliderClass);
		this.currentSlide = 0;
		this.prevSlide = this.slides.length - 1;

		// Initialize first slide
		this.slides[this.currentSlide].classList.add('is-active');
		this.slides[this.prevSlide].classList.add('is-prev');
	}

	showSlide(index) {
		// Remove classes from current slide
		this.slides[this.currentSlide].classList.remove('is-active');

		// Remove classes from previous slide
		if (this.prevSlide !== null) {
			this.slides[this.prevSlide].classList.remove('is-prev');
		}

		// Add classes to new slides
		this.slides[this.currentSlide].classList.add('is-prev');
		this.slides[index].classList.add('is-active');

		// Update indices
		this.prevSlide = this.currentSlide;
		this.currentSlide = index;
	}

	nextSlide() {
		const nextIndex = (this.currentSlide + 1) % this.slides.length;
		this.showSlide(nextIndex);
	}

	startSlider(interval = 5000) {
		setInterval(() => this.nextSlide(), interval);
	}
}
