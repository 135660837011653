export default class ScrollThrottle {
	/**
	 * @class
	 * @constructor
	 * @param {Object} Options
	 * @param {any} Options.target - 監視要素
	 * @param {function} [Options.callback] - コールバック
	 * @param {function} [Options.downEntry] - 下にスクロールして要素が画面内に入った時のコールバック
	 * @param {function} [Options.downLeave] - 下にスクロールして要素が画面外にいった時のコールバック
	 * @param {function} [Options.upEntry] - 上にスクロールして要素が画面内に入った時のコールバック
	 * @param {function} [Options.upLeave] - 上にスクロールして要素が画面外にいった時のコールバック
	 * @param {string} [Options.addClass] - 対象要素に付与するクラス名
	 * @param {boolean} [Options.once=false] - true: 1度のみ監視, false: 常に監視(デフォルト)
	 * @param {Object} [Options.option={}] - 例：{ rootMargin: "0px 0px -100px 0px" }
	 * @param {Number} [Options.first=50] - 初回表示はスクロールを50px検視してから（デフォルト）
	 *
	 * @example
		const scrollObserver = new ScrollObserverFooter({
		target: ".js-scroll-target",
			callback: (entry) => {
				document.body.setAttribute("data-scroll-show", `${!entry.isIntersecting}`);
			},
			downEntry: () => {
				console.log('downEntry');
			},
			upEntry: () => {
				console.log('upEntry');
			},
			option: {
				rootMargin: '0px 0px -100% 0px',
			},
		});

		// 監視開始
		scrollObserver.init();

		// 監視停止
		scrollObserver.destroy();
	 */
	constructor({ target, callback, downEntry, downLeave, upEntry, upLeave, addClass, once = false, option = {}, first = 50 }) {
		this.targetList = typeof target === "string" ? document.querySelectorAll(target) : target;
		this.callback = callback;
		this.downEntry = downEntry;
		this.downLeave = downLeave;
		this.upEntry = upEntry;
		this.upLeave = upLeave;
		this.addClass = addClass;
		this.once = once;
		this.option = option;
		this.first = first;
		this.throttleDelay = 100;
		this.lastRunTime = 0;
		this.rafId = null;

		if (this.downEntry || this.downLeave || this.upEntry || this.upLeave) {
			// 1つ前のY座標
			this.previousY = [];
			this.targetList.forEach((el, i) => {
				el.dataset.scrollId = i;
			});
		}
	}

	/**
	 * @private
	 * @method
	 * @param {Object} observer - IntersectionObserver
	 */
	_setObserver(observer) {
		if (this.targetList.length) {
			this.targetList.forEach((target) => {
				observer.observe(target);
			});
		} else {
			observer.observe(this.targetList);
		}
	}

	/**
	 * @private
	 * @method
	 * @param {Object} observer - IntersectionObserver
	 */
	_unsetObserver(observer) {
		this.targetList.forEach((target) => {
			observer.unobserve(target);
		});
	}

	/**
	 * 	@method
	 *  @description
	 *	監視を開始する
	 */
	init() {
		/**
		 * @private
		 */

		this._observer = new IntersectionObserver(
			this._throttle((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						if (this.addClass) {
							entry.target.classList.add(this.addClass);
						}

						if (this.once) {
							this._observer.unobserve(entry.target);
						}
					} else {
						if (this.addClass) {
							entry.target.classList.remove(this.addClass);
						}
					}

					if (this.callback) {
						this.callback(entry);
					}

					if (this.downEntry || this.downLeave || this.upEntry || this.upLeave) {
						const currentY = entry.boundingClientRect.y;
						const isIntersecting = entry.isIntersecting;
						/**
						 * @type {any}
						 */
						const target = entry.target;
						const id = target.dataset.scrollId;
						if (currentY < this.previousY[id]) {
							if (isIntersecting) {
								// down enter
								if (this.downEntry) {
									this.downEntry(entry);
								}
							} else {
								// down leave
								if (this.downLeave) {
									this.downLeave(entry);
								}
							}
						} else if (currentY > this.previousY[id] && this.previousY[id] !== 0) {
							if (isIntersecting) {
								// up enter
								if (this.upEntry) {
									this.upEntry(entry);
								}
							} else {
								// up leave
								if (this.upLeave) {
									this.upLeave(entry);
								}
							}
						}

						this.previousY[id] = currentY;
					}
				});
			}),
			this.option
		);

		if (this.first) {
			// 初回のみ"scroll"でイベントを発火
			const handleScroll = () => {
				// 指定した数値以上スクロールしたら監視を開始
				if (scrollY < this.first) {
					return;
				}

				this._setObserver(this._observer);

				window.removeEventListener("scroll", handleScroll);
			};

			window.addEventListener("scroll", handleScroll);
		} else {
			this._setObserver(this._observer);
		}
	}
	_throttle(callback) {
		return (entries) => {
			if (this.rafId) {
				cancelAnimationFrame(this.rafId);
			}

			this.rafId = requestAnimationFrame(() => {
				const now = Date.now();
				if (now - this.lastRunTime >= this.throttleDelay) {
					callback(entries);
					this.lastRunTime = now;
				}
			});
		};
	}

	/**
	 *	@method
	 *	@description
	 *	監視を停止する
	 */
	destroy() {
		this._unsetObserver(this._observer);
	}
}
